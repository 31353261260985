.donation-page {
    .hero-modules {
        margin-bottom: 0;
    }

    &:last-child {
        margin-bottom: 120px;
    }

    @include media-breakpoint-down(md) {
        .donation-module-wrapper {
            position: relative;
            top: -20px;
            margin-bottom: -20px;
        }

        .donation-column {
            padding: 0;

            .module:last-child {
                margin-bottom: 50px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .hero-modules {
            margin-bottom: 40px;
        }

        .donation-column,
        .content-column {
            .module:last-child {
                margin-bottom: 80px;
            }
        }
    }
}
