.module-intro-text {
    .heading-block {
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 20px;
    }

    .title {
        position: relative;
        margin: 0;
        padding: 0;
        @include fontSize(18px);
        @include lineHeight(24px);
    }

    .subtitle {
        @include fontSize(24px);
        @include lineHeight(32px);
        margin: 12px 0;
        padding: 0;
        font-family: $fontSecondary;
        font-weight: bold;
        color: $neutral_2;
    }

    p {
        color: #49403A;

        &:last-child {
            margin-bottom: 0;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {

        .heading-block {
           padding: 0;
        }

        .title {
            @include fontSize(40px);
            @include lineHeight(42px);
        }

        .subtitle {
            margin: 0;
            padding: 0;
            @include fontSize(32px);
            @include lineHeight(64px);
        }
    }
}