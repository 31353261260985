.hero-modules {
    .module:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        .module:last-child {
            margin-bottom: 0;
        }
    }
}
.left-modules,
.donation-modules,
.content-column,
.donation-column {

    .module:first-child {
        margin-top: 30px;
    }

    .module:last-child {
        margin-bottom: 30px;
    }

    @include media-breakpoint-up(lg) {
        .module {
            margin: 40px 0;

            &:first-child{
                margin-top: 40px;
            }

            &:last-child {
                margin-bottom: 40px;
            }
        }
    }
}