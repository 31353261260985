﻿#master-footer {
    .charity-icons {
        @include make-col(10);
        @include make-col-offset(1);
    }


    /* 
        Charity Icons 
    */
    .charity-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;

        .icon {
            margin: 0 9px;
            display: flex;
            flex-basis: 33%;
        }

        a {
            display: flex;
            flex-basis: 100%;
        }

        img {
            max-width: 100px;
            margin: auto;
        }
    }


    //992px +
    @include media-breakpoint-up(lg) {
        .charity-icons {
            @include make-col(2);
            @include make-col-offset(1);
            padding: 0;
        }
    }
}
